import React, { useState, useEffect } from "react";
import { 
  getFirestore, 
  collection, 
  getDocs, 
  doc, 
  setDoc, 
  addDoc, 
  deleteDoc, 
  query, 
  orderBy,
  limit,
  getDoc
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";

function AdminPanel() {
  const [activeTab, setActiveTab] = useState("raids");
  const [loading, setLoading] = useState(true);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const auth = getAuth();
  const db = getFirestore();
  const navigate = useNavigate();

  // Authentication check
  useEffect(() => {
    const checkAuth = async () => {
      if (!auth.currentUser) {
        navigate("/login");
        return;
      }
      
      // Check if user is a super admin (admin@goraids.com)
      setIsSuperAdmin(auth.currentUser.email === "admin@goraids.com");
      
      // User is authenticated, we're good to go
      setLoading(false);
    };
    
    checkAuth();
  }, [auth.currentUser, navigate]);

  if (loading) {
    return <div className="container mt-5 text-center">Loading...</div>;
  }

  return (
    <div className="container mt-4">
      <h1 className="mb-4">Admin Panel</h1>
      {isSuperAdmin && (
        <div className="alert alert-info">
          <strong>Super Admin Mode:</strong> You have full system access.
        </div>
      )}
      
      <ul className="nav nav-tabs mb-4">
        <li className="nav-item">
          <button 
            className={`nav-link ${activeTab === 'raids' ? 'active' : ''}`}
            onClick={() => setActiveTab('raids')}
          >
            Raid Management
          </button>
        </li>
        <li className="nav-item">
          <button 
            className={`nav-link ${activeTab === 'users' ? 'active' : ''}`}
            onClick={() => setActiveTab('users')}
          >
            User Management
          </button>
        </li>
        <li className="nav-item">
          <button 
            className={`nav-link ${activeTab === 'stats' ? 'active' : ''}`}
            onClick={() => setActiveTab('stats')}
          >
            Statistics
          </button>
        </li>
        <li className="nav-item">
          <button 
            className={`nav-link ${activeTab === 'settings' ? 'active' : ''}`}
            onClick={() => setActiveTab('settings')}
          >
            App Settings
          </button>
        </li>
      </ul>
      
      {activeTab === 'raids' && <RaidManagement db={db} />}
      {activeTab === 'users' && <UserManagement db={db} isSuperAdmin={isSuperAdmin} />}
      {activeTab === 'stats' && <Statistics db={db} />}
      {activeTab === 'settings' && <AppSettings db={db} isSuperAdmin={isSuperAdmin} />}
    </div>
  );
}

// Raid Management Tab
function RaidManagement({ db }) {
  const [raids, setRaids] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const fetchRaids = async () => {
      try {
        const raidsRef = collection(db, "raids");
        const q = query(raidsRef, orderBy("createdAt", "desc"), limit(50));
        const querySnapshot = await getDocs(q);
        
        const raidsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          startTime: doc.data().startTime?.toDate?.() || new Date(),
          endTime: doc.data().endTime?.toDate?.() || new Date(),
        }));
        
        setRaids(raidsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching raids:", error);
        setLoading(false);
      }
    };
    
    fetchRaids();
  }, [db]);
  
  if (loading) {
    return <div>Loading raids...</div>;
  }
  
  return (
    <div>
      <h2>Raid Management</h2>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Pokemon</th>
              <th>Host</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Participants</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {raids.length === 0 ? (
              <tr>
                <td colSpan="8" className="text-center">No raids found</td>
              </tr>
            ) : (
              raids.map(raid => (
                <tr key={raid.id}>
                  <td>{raid.id.substring(0, 6)}...</td>
                  <td>{raid.pokemonName || 'Unknown'}</td>
                  <td>{raid.hostName || raid.hostId || 'Unknown'}</td>
                  <td>{raid.startTime.toLocaleString()}</td>
                  <td>{raid.endTime.toLocaleString()}</td>
                  <td>{raid.participants?.length || 0}/{raid.maxParticipants || 5}</td>
                  <td>{raid.status || 'Active'}</td>
                  <td>
                    <button className="btn btn-sm btn-danger me-2">Delete</button>
                    <button className="btn btn-sm btn-primary">View</button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

// User Management Tab
function UserManagement({ db, isSuperAdmin }) {
  const [users, setUsers] = useState([]);
  const [adminEmails, setAdminEmails] = useState([]);
  const [moderatorEmails, setModeratorEmails] = useState([]);
  const [loading, setLoading] = useState(true);
  
  // Define user roles
  const roles = {
    ADMIN: 'admin',
    MODERATOR: 'moderator',
    USER: 'user'
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch users
        const usersRef = collection(db, "users");
        const userSnapshot = await getDocs(usersRef);
        
        const usersData = userSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        // Fetch roles
        const rolesRef = doc(db, "settings", "roles");
        const rolesSnapshot = await getDoc(rolesRef);
        
        let adminList = [];
        let modList = [];
        
        if (rolesSnapshot.exists()) {
          const rolesData = rolesSnapshot.data();
          adminList = rolesData.admins || [];
          modList = rolesData.moderators || [];
        }
        
        setAdminEmails(adminList);
        setModeratorEmails(modList);
        setUsers(usersData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    
    fetchData();
  }, [db]);
  
  const getUserRole = (email) => {
    if (adminEmails.includes(email)) {
      return roles.ADMIN;
    } else if (moderatorEmails.includes(email)) {
      return roles.MODERATOR;
    }
    return roles.USER;
  };
  
  const handleRoleChange = async (email, newRole) => {
    if (!isSuperAdmin && newRole === roles.ADMIN) {
      alert("Only super admin can assign admin roles");
      return;
    }
    
    try {
      const rolesRef = doc(db, "settings", "roles");
      const rolesSnapshot = await getDoc(rolesRef);
      
      let admins = [];
      let moderators = [];
      
      if (rolesSnapshot.exists()) {
        const data = rolesSnapshot.data();
        admins = [...(data.admins || [])];
        moderators = [...(data.moderators || [])];
      }
      
      // Remove from all roles first
      admins = admins.filter(e => e !== email);
      moderators = moderators.filter(e => e !== email);
      
      // Add to new role if not basic user
      if (newRole === roles.ADMIN) {
        admins.push(email);
      } else if (newRole === roles.MODERATOR) {
        moderators.push(email);
      }
      
      // Update Firestore
      await setDoc(rolesRef, { 
        admins,
        moderators,
        updated: new Date()
      });
      
      // Update state
      setAdminEmails(admins);
      setModeratorEmails(moderators);
      
      alert(`Role updated: ${email} is now a ${newRole}`);
    } catch (error) {
      console.error("Error updating role:", error);
      alert("Failed to update role");
    }
  };
  
  if (loading) {
    return <div>Loading users...</div>;
  }
  
  return (
    <div>
      <h2>User Management</h2>
      
      <div className="alert alert-warning mb-3">
        <strong>Note:</strong> Only the super admin (admin@goraids.com) can assign admin roles. 
        All admins can assign moderator roles.
      </div>
      
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Email</th>
              <th>Username</th>
              <th>Joined</th>
              <th>Points</th>
              <th>Role</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.length === 0 ? (
              <tr>
                <td colSpan="8" className="text-center">No users found</td>
              </tr>
            ) : (
              users.map(user => (
                <tr key={user.id} className={user.email === "admin@goraids.com" ? "table-info" : ""}>
                  <td>{user.id.substring(0, 6)}...</td>
                  <td>{user.email || 'N/A'}</td>
                  <td>{user.username || user.displayName || 'Anonymous'}</td>
                  <td>{user.createdAt?.toDate?.()?.toLocaleDateString() || 'Unknown'}</td>
                  <td>{user.points || 0}</td>
                  <td>
                    {user.email === "admin@goraids.com" ? (
                      <span className="badge bg-danger">Super Admin</span>
                    ) : (
                      <select 
                        className="form-select form-select-sm"
                        value={getUserRole(user.email)}
                        onChange={(e) => handleRoleChange(user.email, e.target.value)}
                        disabled={!isSuperAdmin && getUserRole(user.email) === roles.ADMIN}
                      >
                        <option value={roles.USER}>User</option>
                        <option value={roles.MODERATOR}>Moderator</option>
                        <option value={roles.ADMIN} disabled={!isSuperAdmin}>Admin</option>
                      </select>
                    )}
                  </td>
                  <td>{user.status || 'Active'}</td>
                  <td>
                    <button className="btn btn-sm btn-warning me-2">Ban</button>
                    <button className="btn btn-sm btn-primary">View</button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      
      <div className="mt-4">
        <h3>Role Summary</h3>
        <div className="row">
          <div className="col-md-6">
            <div className="card mb-3">
              <div className="card-header bg-danger text-white">
                Admins ({adminEmails.length})
              </div>
              <ul className="list-group list-group-flush">
                {adminEmails.length === 0 ? (
                  <li className="list-group-item">No admins configured</li>
                ) : (
                  adminEmails.map((email, index) => (
                    <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                      {email}
                      {email !== "admin@goraids.com" && isSuperAdmin && (
                        <button 
                          className="btn btn-sm btn-outline-danger" 
                          onClick={() => handleRoleChange(email, roles.USER)}
                        >
                          Remove
                        </button>
                      )}
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>
          
          <div className="col-md-6">
            <div className="card">
              <div className="card-header bg-primary text-white">
                Moderators ({moderatorEmails.length})
              </div>
              <ul className="list-group list-group-flush">
                {moderatorEmails.length === 0 ? (
                  <li className="list-group-item">No moderators configured</li>
                ) : (
                  moderatorEmails.map((email, index) => (
                    <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                      {email}
                      <button 
                        className="btn btn-sm btn-outline-danger" 
                        onClick={() => handleRoleChange(email, roles.USER)}
                      >
                        Remove
                      </button>
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// Statistics Tab
function Statistics({ db }) {
  return (
    <div>
      <h2>App Statistics</h2>
      <div className="row mt-4">
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Active Users</h5>
              <h1 className="display-4">243</h1>
              <p className="text-muted">+12% from last week</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Active Raids</h5>
              <h1 className="display-4">18</h1>
              <p className="text-muted">-3% from last week</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Total Points</h5>
              <h1 className="display-4">12,845</h1>
              <p className="text-muted">+8% from last week</p>
            </div>
          </div>
        </div>
      </div>
      
      <div className="row mt-4">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">User Growth</h5>
              <p className="card-text">Chart would go here</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// App Settings Tab
function AppSettings({ db, isSuperAdmin }) {
  const [settings, setSettings] = useState({
    maxRaidsPerUser: 3,
    maxParticipantsPerRaid: 5,
    pointsPerRaid: 10,
    enableNotifications: true
  });
  
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings({
      ...settings,
      [name]: type === 'checkbox' ? checked : value
    });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setDoc(doc(db, "settings", "appSettings"), settings);
      alert("Settings updated successfully!");
    } catch (error) {
      console.error("Error updating settings:", error);
      alert("Failed to update settings.");
    }
  };
  
  return (
    <div>
      <h2>App Settings</h2>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="mb-3">
          <label htmlFor="maxRaidsPerUser" className="form-label">Max Raids Per User</label>
          <input
            type="number"
            className="form-control"
            id="maxRaidsPerUser"
            name="maxRaidsPerUser"
            value={settings.maxRaidsPerUser}
            onChange={handleChange}
          />
        </div>
        
        <div className="mb-3">
          <label htmlFor="maxParticipantsPerRaid" className="form-label">Max Participants Per Raid</label>
          <input
            type="number"
            className="form-control"
            id="maxParticipantsPerRaid"
            name="maxParticipantsPerRaid"
            value={settings.maxParticipantsPerRaid}
            onChange={handleChange}
          />
        </div>
        
        <div className="mb-3">
          <label htmlFor="pointsPerRaid" className="form-label">Points Per Raid</label>
          <input
            type="number"
            className="form-control"
            id="pointsPerRaid"
            name="pointsPerRaid"
            value={settings.pointsPerRaid}
            onChange={handleChange}
          />
        </div>
        
        <div className="mb-3 form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="enableNotifications"
            name="enableNotifications"
            checked={settings.enableNotifications}
            onChange={handleChange}
          />
          <label className="form-check-label" htmlFor="enableNotifications">Enable Notifications</label>
        </div>
        
        <button type="submit" className="btn btn-primary">Save Settings</button>
      </form>
    </div>
  );
}

export default AdminPanel; 