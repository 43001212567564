import React, { createContext, useContext, useEffect, useState } from "react";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { app, db } from "./firebase";
import { doc, getDoc } from "firebase/firestore";

export const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userRole, setUserRole] = useState('user');
  const [loading, setLoading] = useState(true);

  const logOut = async () => {
    const auth = getAuth(app);
    await signOut(auth);
  };

  // Check if user has admin access
  const checkUserRole = async (user) => {
    if (!user) return 'user';
    
    try {
      // Special case for super admin
      if (user.email === "admin@goraids.com") {
        return 'super_admin';
      }
      
      const rolesRef = doc(db, "settings", "roles");
      const rolesSnapshot = await getDoc(rolesRef);
      
      if (rolesSnapshot.exists()) {
        const rolesData = rolesSnapshot.data();
        
        if (rolesData.admins?.includes(user.email)) {
          return 'admin';
        } else if (rolesData.moderators?.includes(user.email)) {
          return 'moderator';
        }
      }
      
      return 'user';
    } catch (error) {
      console.error("Error checking user role:", error);
      return 'user';
    }
  };

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      
      if (user) {
        const role = await checkUserRole(user);
        setUserRole(role);
      } else {
        setUserRole('user');
      }
      
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    userRole,
    logOut,
    isAdmin: () => ['super_admin', 'admin'].includes(userRole),
    isModerator: () => userRole === 'moderator',
    isSuperAdmin: () => userRole === 'super_admin'
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
