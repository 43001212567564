import React, { useState, useEffect } from "react";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import { app } from "../../../utils/firebase";
import { signInWithApple } from "../../../utils/appleAuth";
import { Link, useNavigate } from "react-router-dom";
import AppleSignInButton from "../../../components/Auth/AppleSignInButton";

function Login({ action }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const auth = getAuth(app);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        navigate("/dashboard"); // Update with your protected route
      }
    });

    return unsubscribe; // Cleanup subscription
  }, [auth, navigate]);

  const handleLogin = async () => {
    if (!email || !password) {
      setError("Please enter both email and password");
      return;
    }
    
    setLoading(true);
    setError("");
    
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // Navigation is handled by the onAuthStateChanged listener
    } catch (error) {
      console.error("Error signing in with password and email", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAppleSignIn = async () => {
    setLoading(true);
    setError("");
    
    try {
      await signInWithApple();
      // Navigation is handled by the onAuthStateChanged listener
    } catch (error) {
      console.error("Error signing in with Apple", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center p-4 bg-gray-50">
      <div className="w-full max-w-md">
        <h2 className="mb-6 text-center text-2xl font-bold text-gray-800">Admin Login</h2>
        
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <div className="px-6 py-8">
            {error && (
              <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-md text-sm">
                {error}
              </div>
            )}
            
            <form>
              <div className="mb-4">
                <label htmlFor="emailInput" className="block mb-2 text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  id="emailInput"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter email"
                />
              </div>
              <div className="mb-6">
                <label htmlFor="passwordInput" className="block mb-2 text-sm font-medium text-gray-700">
                  Password
                </label>
                <input
                  type="password"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  id="passwordInput"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
              </div>
              <div>
                <button
                  type="button"
                  className={`w-full py-2 px-4 rounded-md text-white font-medium ${
                    loading 
                      ? 'bg-blue-400 cursor-not-allowed' 
                      : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                  }`}
                  onClick={handleLogin}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Login"}
                </button>
              </div>
              
              <div className="mt-6 text-center">
                <p className="text-sm text-gray-500 mb-4">Or sign in with</p>
                <div className="flex justify-center">
                  <AppleSignInButton onClick={handleAppleSignIn} disabled={loading} />
                </div>
              </div>
              
              <div className="text-center mt-4">
                <Link to="/reset" className="text-sm text-blue-600 hover:text-blue-800">
                  Forgot Password?
                </Link>
              </div>
            </form>
          </div>
        </div>
        
        <div className="text-center mt-6">
          <p className="text-sm text-gray-600">Don't have an account?</p>
          <Link to="/signup" className="mt-1 inline-block text-blue-600 hover:text-blue-800">
            Create one here
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Login;
