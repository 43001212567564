// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDwZAA_TEmXB36WwOsWjFMVuEUfxPXg_8U",
  authDomain: "goraids-409700.firebaseapp.com",
  databaseURL: "https://goraids-409700-default-rtdb.firebaseio.com",
  projectId: "goraids-409700",
  storageBucket: "goraids-409700.appspot.com",
  messagingSenderId: "995067243741",
  appId: "1:995067243741:web:0d2cd71aba0795fdd33f34",
  measurementId: "G-7NPR60W3Q7"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
