// import logo from "./logo.svg";
import "./App.css";

import { AuthProvider } from "./utils/AuthContext";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./views/public/HomePage";
// import Auth from "./views/public/Auth";
import Login from "./views/public/Auth/Login";
import Logout from "./views/public/Auth/Logout";
import PrivateRoute from "./views/middleware/PrivateRoute";
import Dashboard from "./views/private/Dashboard";
import AdminPanel from "./views/private/AdminPanel";
import Test from "./views/Test";
import Bootstrap from "./views/middleware/Bootstrap";
import About from "./views/public/About";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* Public Routes Bootstrap */}
          <Route element={<Bootstrap />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<About />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/test" element={<Test />} />

            {/* Private Routes - Admin Only */}
            <Route element={<PrivateRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/admin" element={<AdminPanel />} />
            </Route>
            
            {/* Redirect all other routes to home */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
