import React, { useState, useEffect } from "react";

function RaidFormRow({ raid, handleAddOrUpdateRaid, handleDeleteRaid, isNew }) {
  const [raidData, setRaidData] = useState(raid);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setRaidData(raid); // Update raid data when raid changes
  }, [raid]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setRaidData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    handleAddOrUpdateRaid(raidData, isNew);
  };

  const confirmDelete = () => {
    setShowModal(true);
  };

  const cancelDelete = () => {
    setShowModal(false);
  };

  const deleteRaid = () => {
    handleDeleteRaid(raid);
    setShowModal(false);
  };

  return (
    <>
      <tr>
        <td>
          <input
            type="text"
            name="pokemonName"
            value={raidData.pokemonName || ""}
            onChange={handleChange}
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            name="pokemonId"
            value={raidData.pokemonId || ""}
            onChange={handleChange}
            className="form-control"
          />
        </td>
        <td>
          <input
            type="date"
            name="startTime"
            value={raidData.startTime || ""}
            onChange={handleChange}
            className="form-control"
          />
        </td>
        <td>
          <input
            type="date"
            name="endTime"
            value={raidData.endTime || ""}
            onChange={handleChange}
            className="form-control"
          />
        </td>
        <td>
          <input
            type="number"
            name="cp"
            value={raidData.cp || ""}
            onChange={handleChange}
            className="form-control"
          />
        </td>
        <td>
          <input
            type="number"
            name="level"
            value={raidData.level || ""}
            onChange={handleChange}
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            name="type"
            value={raidData.type || ""}
            onChange={handleChange}
            className="form-control"
          />
        </td>

        <td style={{ display: "flex" }}>
          <button onClick={handleSubmit} className="btn btn-success">
            {isNew ? "Add" : "Update"}
          </button>
          {!isNew && (
            <button
              onClick={confirmDelete}
              className="btn btn-danger"
              style={{ marginLeft: "10px" }}
            >
              Delete
            </button>
          )}
        </td>
      </tr>

      {showModal && (
        <div
          className="modal show"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Delete</h5>
              </div>
              <div className="modal-body">
                Are you sure you want to delete this raid?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={cancelDelete}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={deleteRaid}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default RaidFormRow;
