import React, { useEffect, useState } from "react";
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  getDocs,
  doc,
  getDoc
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useNavigate, Link } from "react-router-dom";
import RaidTable from "../../components/dashboard/RaidTable/RaidTable";

function Dashboard() {
  const [raids, setRaids] = useState([]);
  const [isAddingRaid, setIsAddingRaid] = useState(false);
  const [userRole, setUserRole] = useState('user');
  const [loading, setLoading] = useState(true);
  const db = getFirestore();
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.currentUser) {
      navigate("/login");
      return;
    }

    // Check user role
    const checkUserRole = async () => {
      try {
        const rolesRef = doc(db, "settings", "roles");
        const rolesSnapshot = await getDoc(rolesRef);
        
        if (rolesSnapshot.exists()) {
          const rolesData = rolesSnapshot.data();
          
          if (auth.currentUser.email === "admin@goraids.com") {
            setUserRole('super_admin');
          } else if (rolesData.admins?.includes(auth.currentUser.email)) {
            setUserRole('admin');
          } else if (rolesData.moderators?.includes(auth.currentUser.email)) {
            setUserRole('moderator');
          } else {
            setUserRole('user');
          }
        }
      } catch (error) {
        console.error("Error checking user role:", error);
      }
    };

    checkUserRole();

    // Fetch raids
    const raidsRef = collection(db, "raids");
    const q = query(raidsRef, where("userId", "==", auth.currentUser.uid));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const raidsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        startTime: doc.data().startTime?.toDate?.() || new Date(),
        endTime: doc.data().endTime?.toDate?.() || new Date(),
      }));
      setRaids(raidsData);
      setLoading(false);
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [db, auth.currentUser, navigate]);

  const addRaid = async (raidData) => {
    if (!auth.currentUser) {
      console.error("No user logged in!");
      return;
    }

    try {
      await addDoc(collection(db, "raids"), {
        ...raidData,
        userId: auth.currentUser.uid,
        createdAt: new Date(),
      });
      setIsAddingRaid(false); // Hide the form after adding
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const hasAdminAccess = () => {
    return ['super_admin', 'admin', 'moderator'].includes(userRole);
  };

  return (
    <div className="container mt-3 mb-3">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>Dashboard</h1>
        <div>
          {userRole === 'super_admin' && (
            <span className="badge bg-danger me-2">Super Admin</span>
          )}
          {userRole === 'admin' && (
            <span className="badge bg-warning me-2">Admin</span>
          )}
          {userRole === 'moderator' && (
            <span className="badge bg-info me-2">Moderator</span>
          )}
          
          {hasAdminAccess() && (
            <Link to="/admin" className="btn btn-primary">
              Admin Panel
            </Link>
          )}
        </div>
      </div>

      <RaidTable raids={raids} isAddingRaid={isAddingRaid} addRaid={addRaid} />
    </div>
  );
}

export default Dashboard;
