import React from "react";

function About() {
  return (
    <div className="container">
      <h1>Welcome to Raid Companion</h1>
      <p>
        Dive into the world of Pokémon Go with Raid Companion, your ultimate
        partner for organizing and participating in Pokémon raids. Our platform
        brings trainers together to collaborate, strategize, and conquer raids
        more effectively.
      </p>
      <p>
        At Raid Companion, we're not just a tool; we're a community hub that
        enhances your raiding experience. Our features are designed to cater to
        trainers of all levels, helping you find raids, join forces with other
        players, and optimize your strategies for battle.
      </p>
      <p>
        Our commitment to the community is reflected in our robust set of tools
        that facilitate real-time coordination and communication. From
        scheduling raids to sharing crucial tips and strategies, Raid Companion
        is your go-to platform for engaging with the Pokémon Go community.
      </p>
      <p>
        We understand the thrill of the hunt and the joy of victory. That's why
        we provide detailed stats and insights into each raid, allowing you to
        track your progress, improve your tactics, and share your achievements
        with friends and fellow trainers.
      </p>
      <p>
        Join Raid Companion today and transform your Pokémon Go adventures.
        Let's make every raid a successful venture, pushing the boundaries of
        what you can achieve in the world of Pokémon Go. Become a part of our
        growing community and explore new ways to enjoy the game you love.
      </p>
    </div>
  );
}

export default About;
