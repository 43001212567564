import { OAuthProvider, signInWithPopup, getAuth } from "firebase/auth";
import { app } from "./firebase";

/**
 * Configure Apple authentication provider
 * @returns {OAuthProvider} Configured Apple auth provider
 */
export const getAppleAuthProvider = () => {
  const provider = new OAuthProvider("apple.com");
  
  // Request user's name and email
  provider.addScope("email");
  provider.addScope("name");
  
  return provider;
};

/**
 * Sign in with Apple
 * @returns {Promise<UserCredential>} Firebase user credential
 */
export const signInWithApple = async () => {
  const auth = getAuth(app);
  const provider = getAppleAuthProvider();
  
  try {
    const result = await signInWithPopup(auth, provider);
    
    // The user may not have a username/email in certain cases
    // Update the user's profile if needed
    const user = result.user;
    const displayName = user.displayName || user.email?.split('@')[0] || 'User';
    
    // Store additional user info if needed
    
    return result;
  } catch (error) {
    console.error("Error signing in with Apple:", error);
    throw error;
  }
};

/**
 * The role checking logic should happen once the user signs in
 * This is already handled in the AuthContext
 */ 