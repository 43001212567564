import React, { useEffect, useState } from "react";
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import RaidFormRow from "./RaidFormRow";

function RaidTable() {
  const [raids, setRaids] = useState([]);
  const [newRaid, setNewRaid] = useState(null);
  const db = getFirestore();
  const auth = getAuth();
  const navigate = useNavigate();
  const settingsDocPath = "Settings/Raids";

  useEffect(() => {
    if (!auth.currentUser) {
      navigate("/login");
      return;
    }
    const fetchRaids = async () => {
      const docRef = doc(db, settingsDocPath);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setRaids(docSnap.data().raidsList || []);
      } else {
        console.log("No such document!");
      }
    };
    fetchRaids();
  }, [db, navigate, auth.currentUser]);

  const handleAddOrUpdateRaid = async (raid, isNew = false) => {
    const docRef = doc(db, settingsDocPath);
    if (isNew) {
      await updateDoc(docRef, { raidsList: arrayUnion(raid) });
      setNewRaid(null);
    } else {
      const updatedRaids = raids.map((r) => (r.id === raid.id ? raid : r));
      await updateDoc(docRef, { raidsList: updatedRaids });
    }
    const updatedSnap = await getDoc(docRef);
    setRaids(updatedSnap.data().raidsList);
  };

  const handleDeleteRaid = async (raid) => {
    const docRef = doc(db, settingsDocPath);
    await updateDoc(docRef, { raidsList: arrayRemove(raid) });
    const updatedSnap = await getDoc(docRef);
    setRaids(updatedSnap.data().raidsList);
  };

  const handleShowAddNewForm = () => {
    setNewRaid({
      pokemonName: "",
      pokemonId: "",
      startTime: "",
      endTime: "",
      cp: "",
      level: "",
      type: "",
    });
  };

  return (
    <div className="container mt-3 mb-3">
      <h1>Welcome to the Pokémon Raids Dashboard</h1>
      <button className="btn btn-primary" onClick={handleShowAddNewForm}>
        Add New Raid
      </button>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Pokemon Name</th>
              <th>ID</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>CP</th>
              <th>Level</th>
              <th>Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {raids.map((raid, index) => (
              <RaidFormRow
                key={index}
                raid={raid}
                handleAddOrUpdateRaid={handleAddOrUpdateRaid}
                handleDeleteRaid={handleDeleteRaid}
              />
            ))}
            {newRaid && (
              <RaidFormRow
                raid={newRaid}
                handleAddOrUpdateRaid={handleAddOrUpdateRaid}
                isNew={true}
              />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default RaidTable;
